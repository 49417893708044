import type { AppProps } from "next/app";
import "@/styles/global.styles.scss";
import Head from "next/head";
import NextNProgress from "nextjs-progressbar";
import ReactQuery from "@/libs/ReactQuery/ReactQuery";
import Notification from "@/libs/Notification/Notification";
import ScrollToTop from "@/components/Tools/ScrollToTop/ScrollToTop";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <ReactQuery>
      <Head>
        <title>ModaBU</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
        {/* Google Analytics Script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-TX7Y03MTSC"></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-TX7Y03MTSC');
            `,
          }}
        />

        {/* Yandex Metrika Script */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(m,e,t,r,i,k,a){
                m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                m[i].l=1*new Date();
                for (var j = 0; j < document.scripts.length; j++) { if (document.scripts[j].src === r) { return; } }
                k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)
              })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

              ym(98772778, "init", {
                  clickmap:true,
                  trackLinks:true,
                  accurateTrackBounce:true,
                  webvisor:true,
                  ecommerce:"dataLayer"
              });
            `,
          }}
        />
      </Head>
      <noscript>
        <div>
          <img src="https://mc.yandex.ru/watch/98772778" style={{ position: 'absolute', left: '-9999px' }} alt="" />
        </div>
      </noscript>
      <NextNProgress color="#fe6f42" showOnShallow={false} options={{
        showSpinner: false,
        trickleSpeed: 0.2
      }} />
      <Notification limit={5} />
      <Component {...pageProps} />
    </ReactQuery>
  );
}
